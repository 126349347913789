<template>
  <v-container fluid>
    <!-- <div class="text-h4 text-md-h4 text-lg-h4 gris--text">Listado recibos</div> -->
    <v-row>
      <v-col>
        <v-header class="gris--text">Busca tus recibos por <strong>año y mes</strong>, envíalos por correo o
          imprímelos.</v-header>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="8" sm="6" md="3" lg="3" xl="2">
        <YearPicker v-model="selectedYear" />
      </v-col>
      <!-- <v-col xs="8" sm="6" md="3" lg="3" xl="2">
        <v-text-field v-model="Busqueda"   label="Busqueda"
        @input="onInputBusqueda" outlined dense></v-text-field>
      </v-col> -->
      <v-col xs="4" sm="4" md="2" lg="1" xl="2" class="text-center">
        <v-btn color="azul_med" @click.native="onClickFunction" dark elevation="0">Buscar</v-btn>
      </v-col>
      <v-col xs="4" sm="4" md="2" lg="1" xl="1" class="text-center">
        <v-btn color="azulclaro" @click.native="onClickDescargar" dark elevation="0"> <v-icon left>mdi-printer</v-icon>
          Imprimir</v-btn>
      </v-col>
      <v-col class="d-flex justify-end">  
        <!-- <v-chip class="ma-1" color="grey" outlined label>
          Faltante: {{ $formatCurrency(this.totalPendientes) }}
      </v-chip> -->
        <div>
          <v-chip class="ma-1" color="azul_med" outlined label>
          Aplicados: {{ $formatCurrency(this.totalPagados) }}
        </v-chip>
        <v-chip class="ma-1" color="azul_med" outlined label>
          Total: {{ $formatCurrency(this.total) }}
        </v-chip>
          <small>Aplicados: {{ this.pagados }} de {{ this.num_recibos }} </small>
          <v-progress-circular :value="Porciento" :size="80" :width="15" color="primary" rotate="-90">
            {{ Porciento }}%
          </v-progress-circular>
        </div>
      </v-col>

    </v-row>
    <div class="cont-bord-gris">
      <v-row no-gutters justify="end">
        <v-col>
          <v-chip class="ma-1" color="grey" outlined label>
            Total recibos: {{ this.num_recibos
            }}
          </v-chip>
          <v-chip color="red" outlined dark label>
            Impago: {{ this.impago
            }}
          </v-chip>
          <v-chip class="ma-1" color="grey" outlined label>
            Pagados: {{ this.pagados
            }}
          </v-chip>
        </v-col>
        <!-- <v-col class="text-sm-h6  red--text">
          <v-list-item-subtitle>Recibos Impago: {{ this.impago }}</v-list-item-subtitle>
        </v-col>
        <v-col class="text-sm-h6 green--text">
          <v-list-item-subtitle>Recibos pagados: {{ this.pagados }}</v-list-item-subtitle>
        </v-col>-->
        <v-col xs="6" sm="6" md="4" lg="4" xl="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar en tabla" single-line hide-details
            outlined dense @change="comp_change()"></v-text-field>
        </v-col>
      </v-row>
      <!-- show-expand :expanded.sync="expanded" :item-class="getRowClass"-->
      <v-data-table group-by="agente" :items-per-page="-1" fixed-header calculate-widths :headers="headers"
        :items="pagos" :search="search" item-key="idpago" class="custom-data-table" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-text': 'Filas por página'
        }" no-data-text="No hay datos disponibles" @click:row="selectRow" :item-class="getRowClass">
        <template v-slot:item="{ item }">
          <tr @click="selectRow(item)" :class="{ 'selected-row': selectedRow && selectedRow === item.idpago }">
            <td>{{ item.idpago }}</td>
            <td>
              <span v-if="item.estado === '1'" class="estatus-activo">Activa</span>
              <span v-if="item.estado === '2'" class="estatus-expirado">Suspendida</span>
              {{ item.contrato }}
            </td>
            <template>
              <td>
                <div class="small-chip">
                  {{ item.NomColectivo }}
                </div>
                {{ item.contratante }}
              </td>
            </template>
            <template>
              <td><span class="creado-style">{{ item.DatePagoFormat }}</span></td>
            </template>
            <template>
              <td><span>{{ item.recibo }} de {{ item.recibos }}</span></td>
            </template>
            <template>
              <td>
                <span v-if="item.Diferencia <= 1 && item.Diferencia > 0 && item.pagado == 0">{{ item.Diferencia }}
                  días</span>
                <span v-if="item.Diferencia > 1 && item.pagado == 0" class="estatus-retardo">{{ item.Diferencia }}
                  días</span>
                <span v-if="item.Diferencia < 0 && item.pagado == 0" style="color: olivedrab;">A tiempo</span>
                <span v-else></span>
              </td>
            </template>
            <!-- <template>
              <td><span v-if="item.estado === '1'" class="estatus-activo">Activa</span>
                <span v-if="item.estado === '2'" class="estatus-expirado">Suspendida</span>
              </td>
            </template> -->
            <template>
              <td v-if="item.pagado === 0">
                IMPAGO
              </td>
              <td v-else dark class="elemento-pagado text-center">
                <div class="small-chip azul_med--text ">
                  {{ item.FormaPago }}
                </div>
                PAGADO
              </td>
            </template>
            <template>
              <td class="text-end"><span class="estatus-activo">{{ $formatCurrency(item.pago) }}</span></td>
            </template>
            <template>
              <v-menu offset-x left :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <td><v-btn @click="selectRow(item)"
                      :class="{ 'selected-row': selectedRow && selectedRow === item.idpago }" red icon v-bind="attrs"
                      v-on="on">
                      <v-icon>mdi-dots-vertical-circle-outline</v-icon>
                    </v-btn></td>
                </template>
                <!-- <registra-pago :isOpen.sync="isModalPagoOpen" :Membresia="item.idcontrato" :FolioPago="item.idpago"
              :Recibo="item.recibo" @form-submitted="handleFormRegistroPago"></registra-pago> -->
                <v-list>
                  <!-- <v-list-item :disabled="!(item.pagado == '0')" @click="registrarPago(item)">
                <v-list-item-title>
                  <v-icon dense color="grey darken-2">
                    mdi-cash-register
                  </v-icon>
                  Registrar pago</v-list-item-title>
              </v-list-item> -->
                  <v-list-item :disabled="false">
                    <email-recibo-modal :ReciboId="item.idpago" :Membresia="item.contrato"
                      :Recibo="`${item.recibo} de ${item.recibos}`" :Titular="item.nombretitular"
                      :Monto="$formatCurrency(item.pago)" :propEmail="item.Email">
                    </email-recibo-modal>
                  </v-list-item>
                  <v-list-item @click="$verRecibo(item.idpago)"><v-icon dense color="grey darken-2">
                      mdi-eye-outline
                    </v-icon> Ver recibo
                  </v-list-item>
                  <v-list-item @click="verPagos(item)">
                    <v-list-item-title>
                      <v-icon dense color="grey darken-2">
                        mdi-list-box-outline
                      </v-icon> Ver recibos pendientes</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row no-gutters>
              <v-col cols="12">
                <v-expand-transition>
                  <v-card flat>
                    <v-card-text>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Nombre contratante: {{ item.contratante }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.IdColectivo">Colectivo: {{ item.NomColectivo
                            }}</v-list-item-subtitle>
                          <v-list-item-subtitle v-if="item.estado == '1'">Estatus:
                            <strong>ACTIVO</strong></v-list-item-subtitle>
                          <v-list-item-subtitle v-else>Estatatus: <strong>SUSPENDIDO</strong></v-list-item-subtitle>
                          <v-list-item-subtitle>Número de pagos: {{ item.recibos }}</v-list-item-subtitle>
                          <v-list-item-subtitle>Renovación: {{ item.renovacion }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-expand-transition>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <list-pagos :isOpen.sync="isModLisPagos" :Membresia="this.MembresiaSelec"
        :NumRecibos="this.NumRecibosSelec"></list-pagos>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'
import YearPicker from '@/components/AnioComponent.vue';
import EmailReciboModal from '@/components/EmailReciboComponent.vue'
//import RegistraPago from '@/components/AplicapagoComponent.vue'
export default {
  name: 'InicioAgente',
  components: {
    YearPicker,
    EmailReciboModal,
    ListPagos: () => import('@/components/ListPagoComponent.vue'),
    //RegistraPago
  },
  computed: {
    ...mapGetters(['getCODAGE'])
  },
  mounted() {
    this.getPagosPendientes()
  },
  watch: {
    selectedYear(val) {
      if (val) {
        this.Busqueda = null
      }
    }
  },
  data: () => ({
    Prciento: 0,
    snackbar: true,
    vertical: true,
    selectedRow: null,
    textAlert: '',
    NumRecibosSelec: '',
    MembresiaSelec: null,
    isModLisPagos: false,
    Busqueda: null,
    reciboSeleccionado: null,
    isModalPagoOpen: false,
    selectedYear: new Date().toISOString().substr(0, 7), // new Date().getFullYear().toString(), or null
    search: '',
    pagos: [],
    expanded: [],
    total: 0,
    totalPagados: 0,
    totalPendientes: 0,
    headers: [
      { text: 'Folio', value: 'idpago' },
      { text: 'Contrato', value: 'contrato', width: '10%' },
      { text: 'Agente', value: 'agente' },
      { text: 'Contratante', value: 'contratante', width: '20%' },
      // { text: 'Colectivo', value: 'NomColectivo', sortable: false },
      { text: 'Fecha pago', value: 'DatePagoFormat', width: '10%' },
      //{ text: 'Recibos pendientes', value: 'NumPagosRetrasados' },
      { text: 'Recibo', value: 'recibo', sortable: false },
      { text: 'Retardo', value: 'Diferencia', sortable: false },
      //{ text: 'Membresía ', value: 'estatus', sortable: false },
      { text: 'Estatus', value: 'pagado' },
      { text: 'Monto', value: 'pago', sortable: false },
      { text: 'Opciones', value: 'ops', sortable: false },
      //{ text: 'Detalle', value: 'data-table-expand', sortable: false },
    ],
    num_recibos: 0,
    pagados: 0,
    impago: 0,
  }),
  methods: {
    onClickDescargar(){
      this.$loading(true)    
      var urltarjet = `${process.env.VUE_APP_NOTIFICACIONES}Pagos/reportePagos?qry=${this.selectedYear}&CodAge=${this.getCODAGE}`
      var newTab = window.open(urltarjet);
      if (newTab) {
        newTab.focus();
        this.$loading(false)
      }
    },
    selectRow(item) {
      this.selectedRow = item.idpago;
    },
    getRowClass(item) {
      return this.selectedRow && this.selectedRow === item.idpago ? 'selected-row' : '';
      //return item.pagado === 1 ? 'pagado-row' : 'impago-row';
    },
    verPagos(data) {
      this.MembresiaSelec = data.contrato
      this.NumRecibosSelec = data.recibos
      this.isModLisPagos = true
    },
    editItem(item) {
      console.log(item)
    },
    deleteItem(item) {
      console.log(item)
    },
    onCleardate() {
      this.selectedYear = null
      this.getPagosPendientes()
    },
    onClickFunction() {
      this.getPagosPendientes()
    },
    verSoloPendientes() {
      this.selectedYear = null
      this.getPagosPendientes()
    },
    getPagosPendientes() {
      this.$loading(true)
      var url = `getCobranza?qry=${this.selectedYear}&CodAge=${this.getCODAGE}`
      ApiBase(
        url,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.$loading(false)
            this.pagos = response.data
            this.num_recibos = this.pagos.length;
            this.impago = this.pagos.filter(i => i.pagado === 0).length
            this.pagados = this.pagos.filter(i => i.pagado === 1).length
            console.log(this.Porciento)
            this.Porciento =  this.Porciento === undefined? 0: ((this.pagados / this.num_recibos) * 100).toFixed(0);
            this.total = this.pagos.reduce((total, pago) => total + pago.pago, 0);
            this.totalPagados = this.pagos.filter(pago => pago.pagado === 1)
              .reduce((total, pago) => total + pago.pago, 0);
            this.totalPendientes = this.pagos.filter(pago => pago.pagado === 0)
              .reduce((total, pago) => total + pago.pago, 0);
          }
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
            position: '',
            // title: 'Valida',
            //text: 'Se genero un error',
            html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> .`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
            //timer: 1500,
          })
        });
    },
    registrarPago(item) {
      console.log(item)
      this.isModalPagoOpen = true
    },
    handleFormRegistroPago(formData) {
      console.log("Form data submitted:", formData);
    },
    onInputBusqueda(value) {
      this.selectedYear = null,
        this.Busqueda = value
    }
  }
}
</script>
<style scoped>
.creado-style {
  font-weight: bold;
  color: #696969;
}

.impago-row .v-data-table__wrapper tbody tr {
  background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .pagado-row {
  background-color: rgb(220, 239, 220) !important;
}

.custom-data-table .impago-row {
  background-color: rgb(255, 255, 25) !important;
}

.custom-data-table .older-row {
  background-color: #ffe4e1;
  /* Color para filas con edad > 30 */
}

.custom-data-table .younger-row {
  background-color: #e0ffff;
  /* Color para filas con edad <= 30 */
}

.elemento-pagado {
  background-color: #c8e8c8;
}

.selected-row {
  background-color: #a0d0f4 !important;
  /* Color de fondo para la fila seleccionada */
}
</style>