<template>
  <v-container fluid>
    
    <v-carousel :height="Slider.height"
    cycle
    :interval="4000">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
    >
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col class="text-center">
            <h1 class="white--text">{{ item.title }}</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-carousel-item>
  </v-carousel>
    <!-- <v-carousel :height="Slider.height" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" reverse-transition="fade-transition"
            transition="fade-transition">
        </v-carousel-item>
    </v-carousel> -->
  </v-container>
</template>
  
<script>
export default {
    name: 'SliderComponent',
    
    data: function () {
        return {
          currentFullDate: this.getCurrentFullDate(),
            items: [
                {
                    src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider1.png?${this.currentFullDate}`,
                },
                {
                    src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider2.png?${this.currentFullDate}`,
                },
                {
                    src: `${process.env.VUE_APP_BASE_IMG}/sliders/slider3.webp?${this.currentFullDate}`,
                }
            ],
        };
    },
    methods: {
      getCurrentFullDate() {
        const today = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return today.toLocaleDateString('es-ES', options); // Ajusta 'es-ES' para el idioma español
      },
    },
    computed: {
      Slider() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return {
              height: '300px',
            };
          case 'sm':
            return {
              height: '350px',
            };
          case 'lg':
            return {
              height: '550px',
            };
          case 'xl':
            return {
              height: '600px',
            };
          default:
            return {
              height: '400px',
            };
        }
      },
    },
}
</script>