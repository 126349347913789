<template>
    <v-container fluid>
        <p v-if="this.IdCot" class="gris--text text-h4">Beneficiarios </p>
        <p v-else class="gris--text text-h4">Beneficiarios </p>
        <p v-if="this.IdCot" class="gris--text ">Listado de Beneficiarios <small><br/> Sí aún no tienes los beneficiarios da clic en <strong>siguiente.</strong></small></p>
        <p v-else class="gris--text ">Ingresa los beneficiarios de la <strong>cotización</strong>. sí aún no tienes los beneficiarios da clic en<strong> siguiente.</strong></p>
        <v-form v-model="valid" ref="form1" @submit.prevent="submitForm">
            <v-row>
                <!-- {{ Object.keys(this.tableData).length }} -->
                <v-col cols="12">
                    <v-row class="justify-end" no-gutters>
                        <v-col cols="auto">
                            <v-btn @click="openModal" color="azul_med" dark elevation="0">Agregar beneficiario</v-btn>
                            <v-dialog v-model="modal">
                                <v-card>
                                    <v-card-title>
                                    </v-card-title>
                                    <v-card-text>
                                        <Beneficiario ref="formBeneficiario" :propTitulo="'Datos del beneficiado'"
                                            :propObjBeneficiario="elBeneficiario" :texTitulo="'Datos del beneficiario'">
                                        </Beneficiario>
                                        <!-- @input-change="updateBeneficiarioValue" -->
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row class="justify-end" no-gutters>
                                            <v-col cols="auto">
                                                <v-btn class="mr-3" @click="closeModal" elevation="0">Cancelar</v-btn>
                                                <v-btn color="azul_med" @click="saveFormBene" elevation="0"
                                                    dark>Agregar</v-btn>

                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="Beneficiarios">
                        <template v-slot:[`item.Edad`]="{ item }">
                            <span v-if="item.Edad === 0" class="edad">{{ item.Edad }}</span>
                            <span v-if="item.Edad === 1" class="edad">{{ item.Edad }} año</span>
                            <span v-if="item.Edad > 1" class="edad">{{ item.Edad }} años</span>
                        </template>
                        <template v-slot:[`item.Estado`]="{ item }">
                            <span v-if="item.Estado === 0" class="estatus-expirado">Suspendido</span>
                            <span v-else class="estatus-activo">Activo</span>
                        </template>
                        <template v-slot:[`item.opc`]="{ item }">
                            <!-- <button @click="removeItem(item)">Quitar</button> -->
                            <router-link class="grey--text" :to="{ name: 'cotizacion', params: { guid: item.Id } }"
                                v-if="item.Estado === 1">Editar </router-link>
                            | <strong @click="DeleteItem(item.Id)" class="grey--text">Quitar</strong>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row class="justify-end mt-3" no-gutters>
                <v-col cols="auto">
                    <v-btn class="mr-3" elevation="0" @click="anterior">Anterior</v-btn>
                    <v-btn color="azul_med" type="submit" dark elevation="0">Siguiente</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import EndPointNet from '@/functions/axios/axios-bearer'
export default {
    name: 'CotizadorPaso2View',
    components: {
        Beneficiario: () => import('@/components/Forms/BeneficiarioComponent.vue')
    },
    created() {
        let IDCOT = this.$route.params.guid;
        if (IDCOT) {
            this.IdCot = IDCOT
            this.listaBeneficiarios()
        }
    },
    data: () => ({
        IdCot: null,
        CotizacionBeneficiarios: {},
        validDatosBenef: false,
        valid: false,
        modal: false,
        elBeneficiario: {},
        localBeneficiario: {},
        BeneficiarioDefault: {
            id: null,
            nombres: '',
            aPaterno: '',
            aMaterno: '',
            rfc: '',
            curp: '',
            email: '',
            fecNac: '',
            estado: '',
            urlFoto: '',
            numTel: '',
            parentesco_id: '',
        },
        Beneficiarios: [],
        headers: [
            { text: 'Nombre', value: 'Nombres' },
            { text: 'Paterno', value: 'APaterno' },
            { text: 'Materno', value: 'AMaterno' },
            { text: 'Nacimiento', value: 'FecNacFormat' },
            { text: 'Edad', value: 'Edad' },
            { text: 'Parentesco', value: 'Parentesco' },
            { text: 'Estatus', value: 'Estado' },
            { text: 'Opciones', value: 'opc' }

        ],
        nextId: 2
    }),
    mounted() {
    },
    methods: {
        openModal() {
            this.modal = true;
        },
        closeModal() {
            this.modal = false;
            this.$refs.formBeneficiario.clearFormBene()
        },
        send_data(data) {
            console.log('Formulario send_data ', data);
        },
        DeleteItem(Item) {
            console.log(Item, this.IdCot)
            this.$swal({
                title: "¿Seguro quieres eliminar este beneficiario?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Si",
                cancelButtonText: `Cancelar`
            }).then((result) => {
                if (result.isConfirmed) {
                    EndPointNet(
                        `Beneficiario/${Item}`,
                        'DELETE'
                    ).then((response) => {
                        if (response.status === 200) {
                            if (response.data) {
                                this.listaBeneficiarios();
                            }
                        }
                    })
                        .catch((error) => {
                            console.log(error)
                            this.$swal({
                                html: `<b>Hubo un error,</b> porfavor intenta más tarde.`,
                                icon: 'error',
                                confirmButtonText: 'Cerrar',
                                showCloseButton: true,
                                showConfirmButton: true,
                            })
                        });
                }
            });


        },
        listaBeneficiarios() {
            this.$loading(true)
            EndPointNet(
                `Beneficiario/${this.IdCot}`,
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        this.Beneficiarios = response.data
                    }
                }
                this.$loading(false)
            })
                .catch((error) => {
                    console.log(error)
                    this.$swal({
                        html: `<b>Hubo un error,</b> porfavor intenta más tarde.`,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: true,
                        showConfirmButton: true,
                    })
                    this.$loading(false)
                });
        },
        submitForm() {
            this.$router.push({ name: 'cotizacion-detalle', params: { guid: this.IdCot } })
        },
        saveFormBene() {
            if (this.$refs.formBeneficiario.validaForm()) {
                this.elBeneficiario.cotizacion_id = this.IdCot
                EndPointNet(
                    'Beneficiario',
                    'POST',
                    this.elBeneficiario

                ).then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            console.log(response.data)
                            this.$swal({
                                position: "top-end",
                                icon: "success",
                                title: "Agregado correctamente",
                                showConfirmButton: false,
                                timer: 1500
                            });
                            this.listaBeneficiarios()
                        }
                        this.elBeneficiario = {}
                        this.closeModal();
                    }
                })
                    .catch((error) => {
                        console.log(error)
                        this.$swal({
                            html: `<b>Hubo un error,</b> porfavor intenta más tarde.`,
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            showCloseButton: true,
                            showConfirmButton: true,
                        })
                        this.closeModal();
                    });
            }

        },
        anterior() {
            this.$router.push({ name: 'cotizacion', params: { guid: this.IdCot } })
        }
    },

}
</script>