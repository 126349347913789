var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-text-field',{staticClass:"col",attrs:{"append-icon":"mdi-magnify","label":"Buscar cita","single-line":"","hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.comp_change()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-responsive",attrs:{"items-per-page":5,"calculate-widths":"","headers":_vm.headers,"items":_vm.data,"item-key":"Id","search":_vm.search,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Filas por página',
        },"no-data-text":"No existen citas en vigencia actual","fixed-header":""},scopedSlots:_vm._u([{key:`item.created_at`,fn:function({ item }){return [_c('td',[(item.created_at)?_c('span',[_vm._v(" "+_vm._s(_vm.$formatDate(item.created_at)))]):_c('span',[_c('v-icon',{attrs:{"dense":"","color":"red darken-2"}},[_vm._v(" mdi-close ")])],1)])]}},{key:`item.FecCierre`,fn:function({ item }){return [(item.FecCierre !== null)?_c('td',[_vm._v(" CERRADA ")]):_c('td',{staticClass:"green--text"},[_vm._v("POR ASISTIR")])]}},{key:`item.ops`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":false}},[_c('email-pase-medico',{attrs:{"Id":item.Id,"propEmail":item.Email}})],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.FecCierre === null),expression:"item.FecCierre === null"}],on:{"click":function($event){return _vm.$verPaseMedico(item.Id)}}},[_c('v-icon',{attrs:{"dense":"","color":"grey darken-2"}},[_vm._v(" mdi-eye-outline ")]),_vm._v(" Ver pase médico ")],1)],1)],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }