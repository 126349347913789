<template>
    <v-container>
      <v-combobox clearable dense hide-selected outlined v-model="selectedValue.Id" :items="items" item-text="Nombre"
        item-value="Id" label="¿Para quién es el pase?" persistent-hint return-object single-line width="180"
        @change="emitValue"></v-combobox>
    </v-container>
  </template>
  
  <script>
  import EndPointNet from "@/functions/axios/axios-bearer";
  
  export default {
    name: "BeneficiadosComponent",
    props: {// Prop para inicializar el valor si es necesario
      initialmembresiaValue: {
        type: String,
        default: "",
      },
    },
    watch: {
      initialmembresiaValue(newVal) { 
        this.membresiaSelected = newVal;
      }
    },
    data() {
      return {
        selectedValue :{
          Id: null
        },
        membresiaSelected : this.initialmembresiaValue,
        items: [],
      };
    },
    created(){
     this.getEspecialidades()   
    },
    methods: {
      emitValue() {
        // Emitir el valor seleccionado al componente padre
        this.$emit("value-changed", this.selectedValue.Id);
      },
     getEspecialidades(){
        this.$loading(true);
      EndPointNet(`/Membresia/beneficiados${this.membresiaSelected}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data;
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos opcionales */
  </style>
