<template>
    <div>
        <v-row justify="end">
        <v-col sm="12" md="4" lg="4" xl="4">
          <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar cita" single-line
            hide-details outlined dense @change="comp_change()"></v-text-field>
        </v-col>
      </v-row>
        <v-data-table :items-per-page="5" calculate-widths :headers="headers" :items="data" item-key="Id"
            class="elevation-1 table-responsive" :search="search" :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Filas por página',
            }" no-data-text="No existen citas en vigencia actual" fixed-header>
            <template v-slot:[`item.created_at`]="{ item }">
                <td>
                    <span v-if="item.created_at">
                        {{ $formatDate(item.created_at) }}</span>
                    <span v-else><v-icon dense color="red darken-2"> mdi-close </v-icon>
                    </span>
                </td>
            </template>
            <template v-slot:[`item.FecCierre`]="{ item }">
                <td v-if="item.FecCierre !== null">
                    CERRADA
                </td>
                <td v-else class="green--text">POR ASISTIR</td>
            </template>
            <template v-slot:[`item.ops`]="{ item }">
                <v-menu bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :disabled="false">
                        <email-pase-medico :Id="item.Id" :propEmail="item.Email">
                        </email-pase-medico>
                    </v-list-item>
                        <v-list-item v-show="item.FecCierre === null" @click="$verPaseMedico(item.Id)"><v-icon dense
                                color="grey darken-2">
                                mdi-eye-outline
                            </v-icon> Ver pase médico
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import EndPointNet from "@/functions/axios/axios-bearer";
import EmailPaseMedico from '@/components/EmailPaseMedicoComponent.vue'

export default {
    name: "BeneficiadosMemComponent",
    components: {
        EmailPaseMedico
    },
    props: {
        Membresia: {
            type: String,
            required: false,
        },
        Estatus: {
            type: Boolean,
            default: false,
            required: false
        },
        NumPases: {
            type: Number,
            default: 0,
            required: false
        },
        opCreaCita: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            search: '',
            data: [],
            headers: [
                //{ text: "Id", value: "Id", sortable: false },
                { text: "Folio", value: "Folio", sortable: false },
                { text: "Parentesco", value: "Parentesco", sortable: false },
                { text: "Beneficiado", value: "Beneficiado", sortable: false },
                { text: "Especialidad", value: "Especialidad", sortable: true },
                { text: "Medico", value: "Medico", sortable: true },
                { text: "Estatus", value: "FecCierre", sortable: true },
                { text: "Creada", value: "created_at", sortable: true },
                { text: "Opciones", value: "ops", sortable: false },
            ],
        };
    },
    watch: {
        Membresia(val) {
            console.log(val)
            val !== null ? this.ObtieneData() : null
        },
    },
    created() {
         this.ObtieneData()
    },
    methods: {
        ObtieneData() {
            if (this.Membresia) {
                this.$loading(true);
                EndPointNet(`membresia/citas${this.Membresia}`, "GET")
                    .then((response) => {
                        if (response.status === 200) {
                            this.data = response.data;
                            this.$loading(false);
                        }
                    })
                    .catch(() => {
                        this.$loading(false);
                    });
            }
        }
    },
};
</script>

<style scoped></style>
