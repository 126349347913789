<template>
  <v-container fluid>
    <!-- Encabezado principal -->
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-medium my-1 hidden-md-and-up">
          Listado de mis beneficiados
        </div>
        <!-- Texto para dispositivos medianos -->
        <div class="text-h5 font-weight-medium hidden-sm-and-down hidden-lg-and-up">
          Listado de mis beneficiados
        </div>

        <!-- Texto para dispositivos grandes -->
        <div class="text-h5  hidden-md-and-down">
          Listado de mis beneficiados
        </div>
        <div v-show="this.Ide" class="text font-weight">
          No existen beneficiados <strong>asociados</strong> a esta membresía {{ this.Ide }}
        </div>
        <div v-show="!this.Ide" class="text font-weight">
          No existen beneficiados <strong>asociados</strong> a esta membresía {{ this.Ide }}
        </div>
      </v-col>
    </v-row>
    <!-- Sección 1: Información Personal -->
    <v-row>
      <v-col cols="12">
        <v-divider class="mb-3"></v-divider>
        <v-data-table :headers="titulos" :items="Beneficiarios">
          <template v-slot:[`item.Edad`]="{ item }">
            <span v-if="item.Edad === 0" class="edad">{{ item.Edad }}</span>
            <span v-if="item.Edad === 1" class="edad">{{ item.Edad }} año</span>
            <span v-if="item.Edad > 1" class="edad">{{ item.Edad }} años</span>
          </template>
          <template v-slot:[`item.FecNac`]="{ item }">
          {{ $formatDate(item.FecNac) }}
        </template>
        <template v-slot:[`item.Status`]="{ item }">
          <td><span v-if="item.Status === 1" class="estatus-activo">Activo</span>
            <span v-if="item.Status === 0" class="estatus-expirado">Suspendido</span>
          </td>
        </template>
        </v-data-table>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApiBase from '@/functions/axios/axiosNetBase'
export default {
  data() {
    return {
      search: '',
      Beneficiarios: [],
      titulos: [
        //{ text: 'GUID', value: 'Id', sortable: false },
        { text: 'Parentesco', value: 'BenParentesco', sortable: false },
        { text: 'Nombres', value: 'Nombres', sortable: false },
        { text: 'Apellido paterno', value: 'Apaterno', sortable: false }, //, hideOnMobile: true 
        { text: 'Apellido materno', value: 'Amaterno', sortable: false },
        { text: 'Fecha Nacimiento', value: 'FecNac', sortable: false },
        { text: 'Edad', value: 'Edad', sortable: false },
        { text: 'Ocupacion', value: 'Ocupacion', sortable: false },
        { text: 'Estatus', value: 'Status', sortable: false }
      ],
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      },
      loading: false

  }
  },
  computed: {
    Ide() {
      return this.$route.params.guid;
    }
  },
  created() {
    this.Obtiene()
  },
  methods: {
    Obtiene() {
      console.log(this.Ide)
      if (this.Ide) {
        ApiBase(
          `getBeneficiarios?Membresia=${this.Ide}`, //${localStorage.getItem('id')}
          'GET'
        ).then((response) => {
          if (response.status === 200) {
            if (response.data) {
              this.Beneficiarios = response.data
            }
          }
        })
          .catch(() => {
            this.$swal({
              position: '',
              html: `Ups, tuvimos un error no esperado <b>intenta más tarde</b> .`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
            })
          });
      }
    },
  }
};
</script>
