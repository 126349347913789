<template>
  <v-container fluid>
    <!-- <v-banner
      v-if="showBanner"
      color="info"
      dense
      outlined
      class="mb-4"
    >
      <template v-slot:icon>
        <v-img
          src="https://medicover.mx/img/imagen-login.webp"
          alt="Descripción de la imagen"
          max-width="50"
        ></v-img>
      </template>
      <div>
        <strong>Mensaje Importante:</strong>
        <p>Este es el contenido de tu mini banner con una imagen.</p>
      </div>
      <template v-slot:actions>
        <v-btn
          icon
          @click="showBanner = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-banner> -->
    <!-- <v-banner v-if="showBanner" color="info" dense outlined class="mb-4">
      <template v-slot:icon>
        <v-icon color="info">mdi-information</v-icon>
      </template>
      <div>
        <strong>Datos Importantes:</strong> Por favor, asegúrate de completar todos los campos obligatorios.
      </div>
      <div class="contact-info">   
        <v-icon left>mdi-information</v-icon>
        <strong>Mi agente: </strong>        
        <v-spacer></v-spacer>
        <span>{{ this.Membresia.AgeNombre }} </span>
        <v-spacer></v-spacer>
        <span><v-icon left>mdi-email</v-icon> {{ this.Membresia.AgeMail }} </span>
        <v-spacer></v-spacer>
        <v-icon left>mdi-phone</v-icon> <span> {{ this.Membresia.AgeTel }}</span>  
        <v-btn text color="info" :href="'tel:' + this.Membresia.AgeTel" small >
          - Llamar
        </v-btn>
      </div>
      <template v-slot:actions>
        <v-btn text color="info" @click="showBanner = false">
          Cerrar
        </v-btn>
      </template>
    </v-banner> -->
    <!-- Encabezado principal -->
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-medium my-1 hidden-md-and-up">
          Te damos la benvenida a tu cuenta. 
        </div>
        <!-- Texto para dispositivos medianos -->
        <div class="text-h5 font-weight-medium hidden-sm-and-down hidden-lg-and-up">
          Te damos la benvenida a tu cuenta.
        </div>

        <!-- Texto para dispositivos grandes -->
        <div class="text-h5  hidden-md-and-down">
          Te damos la benvenida a tu cuenta.
        </div>
        <p class="text-subtitle-1">Si tienes dudas comunicate con tu agente de confianza 
          <strong>{{ this.Membresia.AgeNombre }} </strong> 
          <span><v-icon left>mdi-email</v-icon> {{ this.Membresia.AgeMail }} </span>
          <v-icon left>mdi-phone</v-icon> <a :href="'tel:' + this.Membresia.AgeTel" > {{ this.Membresia.AgeTel }}</a>  
        </p>

      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="5">
        <v-card class="ma-1" outlined>
          <v-card-title class="text-h6 azul_med white--text">
            <v-icon color="white darken-3" class="mr-2">mdi-key-star</v-icon>Membresía {{
              this.Membresia.IdTarjeta
            }}</v-card-title>
          <!-- Imagen del perfil -->
          <!-- <v-card-text class="text-center">
            <v-avatar size="100">
              <img
                src="https://via.placeholder.com/150"
                alt="Imagen de perfil"
              />
            </v-avatar>
          </v-card-text> -->
          <qr-user :id="this.getIdTarjetaTxt"></qr-user>
          <v-card-text class="text-center">
            {{ Membresia.Estatus }}
          </v-card-text>
          <!-- Información del perfil -->
          <v-card-title class="text-center text-h6">{{ this.Membresia.Plan }}</v-card-title>
          <v-card-subtitle class=" grey--text">
            {{ Membresia.TitularNombre }}
          </v-card-subtitle>
          <v-card-text>
            <!-- <ul class="list-none pa-0">
              <li><strong>Email:</strong> juan.perez@email.com</li>
              <li><strong>Teléfono:</strong> +52 55 1234 5678</li>
              <li><strong>Ubicación:</strong> Monterrey, NL</li>
            </ul> -->

            <p>
              <strong>Estatus:</strong> <strong class="green--text">{{ this.Membresia.Estatus }}</strong><br />
              <strong>Número:</strong> {{ this.Membresia.IdTarjeta }}<br />
              <strong>Monto urgencias:</strong> <strong
                class="blue--text">{{ $formatCurrency(this.Membresia.MontoUrgencia) }}</strong><br />
              <strong>Citas médicas disponibles:</strong> {{ this.Membresia.NumCitas }}<br />
              <strong>Vigencia inicio:</strong> {{ $formatDate(this.Membresia.FecVigencia) }}<br />
              <strong>Vigencia fin:</strong> {{ $formatDate(this.Membresia.FecVigenciaFinalTxt) }}<br />
            </p>


            <p v-show="this.Membresia.Colectivo"><strong>Colectividad:</strong> {{ this.Membresia.Colectivo }}</p>

          </v-card-text>
          <!-- Botones de acción -->
          <v-card-actions class="justify-center">
            <!-- <v-btn color="primary" text>Editar Perfil</v-btn>
            <v-btn color="secondary" text>Enviar Mensaje</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="ma-1" outlined>
          <v-card-title class="text-h6 azul_med white--text">
            Listado de Beneficiados </v-card-title>
          <!-- Imagen del perfil -->
          <v-card-text class="text-center">
          </v-card-text>
          <v-card-text>
            <list-beneficiados :Membresia="this.Membresia.IdTarjeta"></list-beneficiados>
            <!-- <ul class="list-none pa-0">
              <li><strong>Nombre: </strong>{{this.Membresia.TitularNombre}}</li>
              <li><strong>Mis Beneficiados</strong>
              
              </li>
            </ul> -->
          </v-card-text>
          <!-- Botones de acción -->
          <v-card-actions class="justify-center">
            <!-- <v-btn color="primary" text>Editar Perfil</v-btn>
            <v-btn color="secondary" text>Enviar Mensaje</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12" sm="4">
        <v-card class="ma-2" outlined>
          <v-card-title class="text-h6 azul_med white--text">
            Mi agente </v-card-title>
          <v-card-text class="text-center">
          </v-card-text>
          <v-card-text>
            <ul class="list-none pa-0">
              <li><strong>Teléfono:</strong> <strong class="green--text">
                  {{ this.Membresia.AgeTel }} </strong>
                <v-btn color="primary" :href="'tel:' + this.Membresia.AgeTel" small outlined>
                  Llamar
                </v-btn>
              </li>
              <li><strong>Correo:</strong> {{ this.Membresia.AgeMail }} </li>
            </ul>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" text>Editar Perfil</v-btn>
            <v-btn color="secondary" text>Enviar Mensaje</v-btn>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h6 font-weight-bold mb-2">Contacto de membresía</h2>
        Manten actualizado tu contacto para recibir notificaciones importantes y no te pierdas cada detalle de tu
        membreía
        <v-divider class="mb-3"></v-divider>
        <v-form >
          <v-row>
            <v-col cols="6" xs="6" sm="3" md="3" lg="3" xl="3">
              <v-text-field v-model="Membresia.EmailContacto" label="Correo" :rules="[ruleEmail]" outlined dense></v-text-field>
            </v-col>
            <v-col cols="6" xs="6" sm="3" md="3" lg="2" xl="2">
              <v-text-field v-model="Membresia.TelContacto" :rules="[ruleTelephone]" label="Teléfono" :counter="10" outlined dense></v-text-field>
            </v-col>

            <v-col cols="6" xs="6" sm="3" md="3" lg="2" xl="2">
              <v-btn color="primary"  @click="actualizarContacto" dense outlined>
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h6 font-weight-bold">Mi red hospitalaria</h2>
        Acude ante cualquier urgencia médica y presenta tu credencial digital.
        <v-divider class="mb-2"></v-divider>
        <v-row>
          <v-col v-for="(image, index) in redhospitalaria" :key="index" cols="12" sm="3" md="3" lg="2"
            class="d-flex justify-center">
            <!-- Tarjeta de imagen -->
            <v-card v-show="image.membresia === Membresia.Plan" class="ma-2" max-width="100%" contain>
              <v-img :src="image.src" :alt="image.alt" width="100%" height="auto"></v-img>
              <v-card-subtitle>
                <v-btn :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(image.direccion)}`"
                  target="_blank" text color="primary" small block>
                  ver Direccion
                </v-btn>

              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import EndPointNet from '@/functions/axios/axios-bearer'


export default {
  name: 'InicioCliente',
  components: {
    QrUser: () => import('@/components/user/Qruser.vue'),
    ListBeneficiados: () => import('@/components/BeneficiariosMemComponent.vue')
  },
  created() {
    this.getMembresia()
  },
  data: () => ({
    showBanner: true,
    profileUrl: `https://example.com/profile/juan-perez/`,
    Membresia: {
      IdTarjeta: '',
      IdTarjetaTxt: '',
      IdUsuario: '',
      FecVigencia: '',
      FecVigenciaFinalTxt: '',
      Estatus: 'INACTIVO',
      NumCitas: 0,
      NumBeneficiados: 0,
      MontoUrgencia: 0,
      AgeNombre: '',
      AgeMail: '',
      AgeTel: '',
      TitularNombre: '',
      TelContacto: '',
      EmailContacto: '',
      UpdateContacto: '',
      Plan: '',
      NomContratante: '',
      Colectivo: null,
      Beneficiados: []
    },
    redhospitalaria: [

    { src: "https://medicover.mx/img/Logolabene.png", alt: "Imagen 3", direccion:"Av. Venustiano Carranza 1090, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "PLUS - FAMILIAR" },
    { src: "https://medicover.mx/img/LogHospitalAngeles.webp", alt: "Imagen 1", direccion: "Antonio Aguilar 155, Burocratas del Estado, 78213 San Luis Potosí, S.L.P.",
     membresia: "PLUS - FAMILIAR"},
    { src: "https://medicover.mx/img/LogoST.svg", alt: "Imagen 2", direccion: "Mariano Arista 735, Barrio de Tequisquiapan, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "PLUS - FAMILIAR" },
    { src: "https://medicover.mx/img/logohs.png", alt: "Imagen 4", direccion: "Av. Benito Juarez 1210, Valle Dorado, 78399 San Luis Potosí, S.L.P.", membresia: "PLUS - FAMILIAR"},
    { src: "https://medicover.mx/img/logolomas.png", alt: "Imagen 5", direccion: "Imagen 5", membresia: "PLUS - FAMILIAR" },

      { src: "https://medicover.mx/img/Logolabene.png", alt: "Imagen 3", direccion: "Av. Venustiano Carranza 1090, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "FAMILIAR CORP. FW" },
      { src: "https://medicover.mx/img/Logolabene.png", alt: "Imagen 3", direccion: "Imagen 3", membresia: "INDIVIDUAL CORP. FW" },
 


      /**INDIVIDUAL CORPORATIVA DE SELLOS Y RETENES */
      { src: "https://medicover.mx/img/Logolabene.png", alt: "Imagen 3", direccion: "Av. Venustiano Carranza 1090, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "INDIVIDUAL - CORPORATIVA" },
      { src: "https://medicover.mx/img/LogoST.svg", alt: "Imagen 3", direccion: "Mariano Arista 735, Barrio de Tequisquiapan, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "INDIVIDUAL - CORPORATIVA" },
      { src: "https://medicover.mx/img/logohs.png", alt: "Imagen 3", direccion: "Av. Benito Juarez 1210, Valle Dorado, 78399 San Luis Potosí, S.L.P.", membresia: "INDIVIDUAL - CORPORATIVA" },

      { src: "https://medicover.mx/img/Logolabene.png", alt: "Imagen 3", direccion: "Av. Venustiano Carranza 1090, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "FAMILIAR - CORPORATIVA" },
      { src: "https://medicover.mx/img/LogoST.svg", alt: "Imagen 3", direccion: "Mariano Arista 735, Barrio de Tequisquiapan, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "FAMILIAR - CORPORATIVA" },
      { src: "https://medicover.mx/img/logohs.png", alt: "Imagen 3", direccion: "Av. Benito Juarez 1210, Valle Dorado, 78399 San Luis Potosí, S.L.P.", membresia: "FAMILIAR - CORPORATIVA" },



      {
        src: "https://medicover.mx/img/LogHospitalAngeles.webp", alt: "Imagen 1",
        direccion: "Antonio Aguilar 155, Burocratas del Estado, 78213 San Luis Potosí, S.L.P.", membresia: ""
      },
      { src: "https://medicover.mx/img/LogoST.svg", alt: "Imagen 2", direccion: "Mariano Arista 735, Barrio de Tequisquiapan, De Tequisquiapan, 78250 San Luis Potosí, S.L.P.", membresia: "" },

      { src: "https://medicover.mx/img/logohs.png", alt: "Av. Benito Juarez 1210-CONSULTORIO 205, SEGUNDO, 78399 PISOs Potosí, S.L.P.", direccion: "Imagen 4", membresia: "" },
      { src: "https://medicover.mx/img/logolomas.png", alt: "Imagen 5", direccion: "Imagen 5", membresia: "" },
    ],
  }),
  mounted() {
  },
  computed: {
    ...mapGetters(['getIdTarjetaTxt','getIdTarjeta']),
    formattedMonto() {
      // Formatea el monto como moneda
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.Membresia.MontoUrgencia);
    },
    formattedDate() {
      // Formatea la fecha solo al formato deseado (YYYY-MM-DD)
      if (!this.Membresia.FecVigencia) return '';
      return this.Membresia.FecVigencia.split('T')[0]; // Quita hora, minuto, segundos
    }
  },
  methods: {
    
    actualizarContacto(){

      const nuevoValor = this.getIdTarjeta;
      console.log(this.Membresia.TelContacto, this.Membresia.EmailContacto)
      if(this.Membresia.TelContacto === null && this.Membresia.EmailContacto === null){
        console.log("inform")
      }else{
      this.$loading(true)
      EndPointNet(
        `Membresia/updateContacto`,
        'PUT',
        {
          GUID : nuevoValor,
          NumTel: this.Membresia.TelContacto,
          Email: this.Membresia.EmailContacto
        }
      ).then((response) => {
        if (response.status === 200) {
          this.$loading(false)
          this.$swal({
                        position: 'top-end',
                        title: 'Correcto',
                        text: 'Se guardaron los datos',
                        icon: 'success',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 1500
                    })
        }
      })
        .catch(() => {
          this.$loading(false)
          this.$swal({
              position: '',
              html: `Ups, tuvimos un error no esperado <b>intenta más tarde</b> .`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
            })
        });
      }
    },
    updateMonto(value) {
      // Convierte el valor del input en un número sin formato
      const rawValue = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
      this.Membresia.MontoUrgencia = rawValue;
    },
    updateDate(value) {
      // Mantener solo la fecha (YYYY-MM-DD)
      const formatted = value.split('T')[0]; // Quita hora, minuto, segundos
      this.Membresia.FecVigencia = formatted;
    },
    getMembresia() {
      this.$loading(true)
      EndPointNet(
        `User/Membresia?idTarjeta=${this.getIdTarjetaTxt}`,
        'GET'
      ).then((response) => {
        console.log(response.data)
        if (response.status === 200) {
          this.Membresia = response.data
          this.$loading(false)
        }
      })
        .catch(() => {
          this.$loading(false)
        });
    }
  }
};                                      
</script>
<style scoped>
.contact-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contact-info v-icon {
  margin-right: 8px;
}
</style>