<template>
    <v-container>
        <div class="text-h4 gris--text">Detalle de cotización</div>
        <v-row class="mt-1 cont-gris">
            <v-col cols="12" md="4">
                <p class="font-weight-medium titulo">Plan seleccionado: <span class="titulo-val">{{ this.cotizacion.Plan
                        }}</span></p>
            </v-col>
            <v-col cols="12" md="4">
                <p class="font-weight-medium titulo">Tipo de pago: <span class="titulo-val">{{ this.cotizacion.Fraccion
                        }}</span></p>
            </v-col>
            <v-col cols="12" md="4">
                <p class="font-weight-medium titulo text-right">Vigencia al: <span class="titulo-val">{{
                    this.cotizacion.FecVigFormat }} </span> | Folio: <span class="titulo-val">{{
                    this.cotizacion.Folio }}
                    </span></p>
            </v-col>
        </v-row>
        <v-row class="mt-1 ">
            <v-col cols="12" xs="12" sm="12" md="4" lg="12" xl="3">
                <div class="text-h6 gris--text">Coberturas médicas</div>
                <div class="cont-gris container-fluid">
                    <!-- <p class="gris--text text-h6">Coberturas seleccionadas</p> -->
                    <div v-for="(cobertura, index) in this.cotizacion.Coberturas" :key="index">
                        <div v-if="cobertura.Selected === 1">
                            <v-icon color="azul_med" small>mdi-check</v-icon> <span class="titulo-val">{{
                    cobertura.Nombre }}</span> <small>{{ cobertura.Descripcion }} </small>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="12" xl="9">
                <div class="text-h6 gris--text">Detalle de pago</div>
                <div class="cont-gris container-fluid">
                    <v-data-table :headers="headersPago" :items="this.detallepago" hide-default-footer
                        style="height:100px; overflow-y: auto;" class="small-table">
                        <template v-slot:[`item.Inicial`]="{ item }">
                            <span>{{ item.Inicial | currency }}</span>
                        </template>
                        <template v-slot:[`item.Subsecuentes`]="{ item }">
                            <span v-if="item.OpcionPago === 'ANUAL'"></span>
                            <span v-else>{{ item.Subsecuentes | currency }}</span>
                        </template>
                        <template v-slot:[`item.Expedicion`]="{ item }">
                            <span>{{ item.Expedicion | currency }}</span>
                        </template>
                        <template v-slot:[`item.Descuento`]="{ item }">
                            <span v-if="item.Descuento !== 0">{{ item.Descuento | currency }}</span>
                        </template>
                        <template v-slot:[`item.Subtotal`]="{ item }">
                            <span>{{ item.Subtotal | currency }}</span>
                        </template>
                        <template v-slot:[`item.Total`]="{ item }">
                            <span>{{ (item.Total + item.Expedicion) | currency }}</span>
                        </template>
                        <template v-slot:[`item.Seleccionado`]="{ item }">
                            <span v-if="item.Seleccionado === 1"> <v-icon>mdi-check</v-icon></span>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div class=" container-fluid">
                    <DatosPersonales ref="datosPersonales" :propTitulo="'Datos del titular'" :propObjUser="cotizacion">
                    </DatosPersonales>
                </div>
            </v-col>
            <v-col cols="12">
                <div class="container-fluid">
                    <div v-if="this.cotizacion.Beneficiarios.length > 0">
                        <p class="gris--text text-h5 left">Beneficiarios</p>
                        <v-data-table :headers="headers" :items="this.cotizacion.Beneficiarios">
                            <template v-slot:[`item.Edad`]="{ item }">
                                <span v-if="item.Edad === 0" class="edad">{{ item.Edad }}</span>
                                <span v-if="item.Edad === 1" class="edad">{{ item.Edad }} año</span>
                                <span v-if="item.Edad > 1" class="edad">{{ item.Edad }} años</span>
                            </template>
                            <template v-slot:[`item.Estado`]="{ item }">
                                <span v-if="item.Estado === 0" class="estatus-expirado">Suspendido</span>
                                <span v-else class="estatus-activo">Activo</span>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row class="justify-end mt-3" no-gutters>
            <v-col cols="auto">
                <v-btn class="mr-3" elevation="0" @click="$router.push({ name: 'agente-cotizaciones' })">Ir a
                    cotizaciones</v-btn>
                <v-btn color="azul_med" @click="DescargarCotizacion" dark elevation="0">Descargar</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import EndPointNet from '@/functions/axios/axios-bearer'
import { mapGetters } from 'vuex'

export default {
    name: 'CotizadorView',
    components: {
        DatosPersonales: () => import('@/components/Forms/DatosPersonalesComponent.vue'),
    },
    computed: {
        ...mapGetters(['getName', 'getApellidos', 'getEmail'])
    },
    mounted() {
        this.emailAgent = this.getEmail
        this.nombreAgent = `${this.getName} ${this.getApellidos}`
    },
    data: () => ({
        emailAgent: "",
        nombreAgent: "",
        cotizacion: {
            Beneficiarios: {}
        },
        detallepago: [{}],
        headers: [
            { text: 'Parentesco', value: 'Parentesco' },
            { text: 'Nombre', value: 'Nombres' },
            { text: 'Paterno', value: 'APaterno' },
            { text: 'Materno', value: 'AMaterno' },
            { text: 'Nacimiento', value: 'FecNacFormat' },
            { text: 'Edad', value: 'Edad' },
            { text: 'Estatus', value: 'Estado' }
        ],
        headersPago: [
            { text: 'Seleccionado', value: 'Seleccionado', sortable: false },
            { text: 'Plan pago', value: 'OpcionPago', sortable: false },
            { text: 'Gastos expedición (1er pago)', value: 'Expedicion', sortable: false },
            { text: 'Primer pago', value: 'Inicial', sortable: false },
            { text: 'Subsecuentes', value: 'Subsecuentes', sortable: false },
            { text: 'Descuento', value: 'Descuento', sortable: false },
            // { text: 'Subtotal', value: 'Subtotal', sortable: false },
            { text: 'Total', value: 'Total', sortable: false }
        ],
    }),
    created() {
        let IDCOT = this.$route.params.guid;
        if (IDCOT) {
            this.IdCot = IDCOT
            this.obtieneCotizacion()
        }
    },
    methods: {
        async DetallePago() {
            this.$loading(true)

            await EndPointNet(
                `/Cotizacion/Precios?idecotizacion=${this.IdCot}`,
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        this.detallepago = response.data
                        this.$loading(false)
                    }
                }
            })
        },
        obtieneCotizacion() {
            this.$loading(true)
            EndPointNet(
                `Cotizacion/${this.IdCot}`,
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        this.cotizacion = response.data
                        this.tipoPlan = response.data.TipoPlan
                        this.DetallePago()
                        // this.Cotizacion = response.data
                        this.$loading(false)
                    }
                }
            })
                .catch((error) => {
                    console.log(error)
                    this.$swal({
                        html: `<b>Hubo un error,</b> porfavor intenta más tarde.`,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        showCloseButton: true,
                        showConfirmButton: true,
                    })
                    this.$loading(false)
                });
        },
        async DescargarCotizacion() {
            this.$globalMethod(this.IdCot, this.nombreAgent, this.emailAgent)
        },
    }
}
</script>
<style scoped>
.titulo {

    color: #a3a1a1;
}

.titulo-val {
    font-weight: bold;
    color: #5e5e5e;
    font-size: 16px;
}

.small-table {
    font-size: 12px;
    /* Reduce el tamaño de la fuente */
    height: 300px;
    /* Altura fija de la tabla */
    overflow-y: auto;
    /* Permitir desplazamiento si el contenido excede la altura */
}

.small-table .v-data-table__wrapper {
    height: 100%;
    /* Asegurar que el wrapper ocupe el 100% de la altura del contenedor */
}

.small-table .v-data-table-header,
.small-table .v-data-table__body>tr {
    padding: 4px;
    /* Reduce el padding de las filas y encabezados */
}

.small-table .v-data-table__wrapper td,
.small-table .v-data-table__wrapper th {
    padding: 4px;
    /* Reduce el padding de las celdas */
}
</style>