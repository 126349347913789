<template>
    <v-container fluid>

        <p v-if="this.IdCot" class="gris--text text-h4 ">Datos de cotización</p>
        <p v-else class="gris--text text-h4">Nueva Cotizacion </p>
        <div class="text-subtitle-1">1. Ingresa los datos requeridos para generar una <strong
                class="azul_med--text">cotización</strong>.</div>
        <br />
        <v-form v-model="valid" ref="form1">
            <!-- @submit.prevent="submitForm" -->
            <v-row>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <Planes ref="tipoPlan" @tipoPlan-change="updateTipoPlanValue" @plan-change="updatePlanValue"
                        :propPlan="cotizacion.plan_id" :propTipoPlan="tipoPlan"></Planes>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <TipoPago ref="tipoPago" @periodo-change="updatePeriodoValue" :propTipo="0"
                        :propPeriodo="cotizacion.fraction_id"></TipoPago>
                </v-col>
                <v-col cols="12">
                    <div class="text-subtitle-1">2. Selecciona las coberturas, las color <strong
                            class="azul_med--text">azul</strong> son
                        opcionales.</div>
                    <div class="cont-gris container-fluid">
                        <Coberturas :propCoberturas="cotizacion.Coberturas">
                        </Coberturas>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="text-subtitle-1">3. Por favor, completa los siguientes datos. Para una mejor
                        presentación en la cotización, asegúrate de <strong class="azul_med--text">llenar todos
                        </strong> los
                        campos.</div>
                        <br/>
                    <datos-personales ref="datosPersonales" :propTitulo="'Datos del titular'" :propObjUser="cotizacion">
                    </datos-personales>
                </v-col>
                <!-- <v-col cols="12">
                    <Direccion ref="datosDireccion" :propObjUserDireccion="cotizacion.direccion"
                        :propTitulo="'Dirección del titular'"></Direccion>
                </v-col> -->
            </v-row>
            <v-row class="justify-end">
                <v-col cols="auto">
                    <v-btn class="mr-3" @click="Atras" elevation="0">Atrás</v-btn>
                    <v-btn v-if="this.tipoPlan === 'Familiar'" color="azul_med" @click="submitForm('beneficiarios')"
                        dark elevation="0">Agregar Beneficiarios</v-btn>
                    <v-btn v-else color="azul_med" @click="submitForm('cotizacion')" dark elevation="0">Cotizar</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import EndPointNet from '@/functions/axios/axios-bearer'
import moment from 'moment'
export default {
    name: 'CotizadorView',
    components: {
        Planes: () => import('@/components/Forms/PlanesComponent.vue'),
        TipoPago: () => import('@/components/Forms/TipoPagoComponent.vue'),
        Coberturas: () => import('@/components/Forms/CoberturasComponent.vue'),
        DatosPersonales: () => import('@/components/Forms/DatosPersonalesComponent.vue'),
    },
    data: () => ({
        IdCot: null,
        valid: false,
        tipoPlan: '',
        cot_valida: false,
        cotizacion: {
            Id: null,
            FecVig: null,
            FecNac: null,
            Nombres: null,
            APaterno: null,
            AMaterno: null,
            Sexo: null,
            RFC: null,
            CURP: null,
            Email: null,
            Estado: 0,
            NumTel: null,
            datosfiscales_id: null,
            direccion_id: null,
            direccion: {
                id: null,
                cp: null,
                estado: null,
                municipio: null,
                asentamiento: null,
                calle: null,
                numint: null,
                numext: null,
            },
            plan_id: null,
            fraction_id: null,
            Coberturas: []
        }
    }),
    mounted() {
    },
    created() {
        let IDCOT = this.$route.params.guid;
        if (IDCOT) {
            this.IdCot = IDCOT
            this.obtieneCotizacion()
        }
        if (!IDCOT) {
            this.getCoberturas()
        }
    },
    methods: {
        handleSelectionChanged(value) {
            this.selectedItem = value;
        },
        getCoberturas() {
            EndPointNet(
                `Cobertura`,
                'GET'
            ).then((response) => {
                if (response.status === 200) {
                    this.cotizacion.Coberturas = response.data
                    this.cotizacion.Coberturas.forEach(element => {
                        if (element.Nombre === 'Consultas' || element.Nombre === 'Urgencias') {
                            element.Selected = 1;
                        } else {
                            element.Selected = 0;
                        }
                    });
                }
                this.$loading(false)
            })
                .catch(() => {
                    this.$loading(false)
                });
        },
        Atras() {
            this.$router.push({ name: 'agente-cotizaciones' })
        },
        updatePlanValue(value) {
            this.cotizacion.plan_id = value
        },
        updateTipoPlanValue(value) {
            this.tipoPlan = value
        },
        updatePeriodoValue(idperiodo) {
            this.cotizacion.fraction_id = idperiodo
        },
        updateDirTitularValue(value) {
            this.validDatosDirection = (value === false) ? false : true;
            // console.log("updateTitularValue "+ value)
        },
        obtieneCotizacion() {
            var isGUID = this.IdCot?.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$")
            if (isGUID) {
                console.log(isGUID)
                EndPointNet(
                    `Cotizacion/${this.IdCot}`,
                    'GET'
                ).then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            response.data.Coberturas.forEach(element => {
                                if (element.Selected) {
                                    element.Selected = 1;
                                } else {
                                    element.Selected = 0;
                                }
                            });

                            this.cotizacion.FecNac = moment(response.data.FecNac).format('YYYY-MM-DD')
                            this.cot_valida = true
                            this.cotizacion = response.data
                            this.tipoPlan = response.data.TipoPlan

                        }
                    }
                })
                    .catch((error) => {
                        console.log(error)
                        this.$swal({
                            position: '',
                            // title: 'Valida',
                            //text: 'Se genero un error',
                            html: `Cotización no encontrada <b>GUID incorrecto</b> .`,
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            showCloseButton: true,
                            showConfirmButton: true,
                            //timer: 1500,
                        })
                    });
            }
        },

        submitForm(ir) {
            if (this.$refs.datosPersonales.validaForm() && this.$refs.tipoPlan.validaForm() && this.$refs.tipoPago.validaForm()) {
                this.$loading(true)
                EndPointNet(
                    `Cotizacion`,
                    'POST',
                    this.cotizacion
                ).then((response) => {
                    if (response.status === 200) {
                        if (ir === 'beneficiarios') {
                            this.$router.push({ name: 'cotizacion-benef', params: { guid: response.data } })
                        }
                        if (ir === 'cotizacion') {
                            this.$router.push({ name: 'cotizacion-detalle', params: { guid: response.data } })
                        }
                        this.$loading(false)
                    } else {
                        this.$loading(false)
                    }

                })
                    .catch((error) => {
                        console.log(error)
                        this.$loading(false)
                        this.$swal({
                            position: '',
                            // title: 'Valida',
                            //text: 'Se genero un error',
                            html: `Hubo un error, por favor <b>intenta de nuevo mas tarde</b> .`,
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            showCloseButton: true,
                            showConfirmButton: true,
                            //timer: 1500,
                        })
                    });

            }
        }
    }
}
</script>