<template>
  <v-container>
    <!-- Diálogo -->
    <v-dialog v-model="isModal" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Nuevo pase médico</span>
        </v-card-title>

        <v-card-text>
          Elige la especialidad médica que necesitas, selecicona el médico de tu preferencia y cuál de tus beneficiarios
          acudirá a la cita.
          <especialidades-component @value-changed="updateEsp"></especialidades-component>
          <medicos-component v-show="this.especialidad" @value-changed="updateMed"
            :especialidadValue="this.especialidad"></medicos-component>
          <benediciados-component v-show="this.medico" @value-changed="updateBen"></benediciados-component>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Botón para cerrar el diálogo -->
          <v-btn text @click="cerrar">
            CERRAR
          </v-btn>
          <v-btn color="primary" text @click="crearCita">
            CREAR CITA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-medium my-1 hidden-md-and-up">
          Listado de citas médicas realizadas.
        </div>
        <div class="text-h5 font-weight-medium hidden-sm-and-down hidden-lg-and-up">
          Listado de citas médicas realizadas.
        </div>
        <div class="text-h5 hidden-md-and-down">
          Listado de citas médicas realizadas.
        </div>
        <v-header class="gris--text">Utiliza tus citas médicas y cuida de tu familia
          <strong>familia</strong> 
          <v-chip v-if="this.numerocitas > 0 && this.estado === 1" class="ma-2" color="green darken-3" outlined small>
            <v-avatar left>
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            Citas disponibles {{ this.numerocitas }}
          </v-chip>
          <v-chip v-else class="ma-2" color="red darken-3" outlined small>
            <v-avatar left>
              <v-icon>mdi-alert-circle</v-icon>
            </v-avatar>
            Citas disponibles {{ this.numerocitas }}
          </v-chip> recuerda que puedes <strong>agregar citas</strong>  a precio especial, aprovecha al máximo tu membresía, contacta a tu agente o comunicate con MediCover.
        </v-header>
      </v-col>
    </v-row>
    <v-form>
      <v-row justify="end">
        <v-btn v-show="this.numerocitas > 0 && this.estado === 1" color="azul_med" @click.native="GenerarPase" dark>+
          Generar pase médico</v-btn>
      </v-row>
      <v-row justify="end">
        <v-col sm="12" md="4" lg="4" xl="4">
          <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar cita" single-line
            hide-details outlined dense @change="comp_change()"></v-text-field>
        </v-col>
      </v-row>
      <div>
        <v-data-table :items-per-page="5" calculate-widths :headers="headers" :items="list" item-key="Id"
          class="elevation-1 table-responsive" :search="search" :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Filas por página',
          }" no-data-text="No existen registros" fixed-header>
          <template v-slot:[`item.created_at`]="{ item }">
            <td>
              <span v-if="item.created_at">
                {{ $formatDate(item.created_at) }}</span>
              <span v-else><v-icon dense color="red darken-2"> mdi-close </v-icon>
              </span>
            </td>
          </template>
          <template v-slot:[`item.FecCierre`]="{ item }">
            <td v-if="item.FecCierre !== null">
              CERRADA
            </td>
            <td v-else class="green--text">POR ASISTIR</td>
          </template>
          <template v-slot:[`item.ops`]="{ item }">
            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-show="item.FecCierre === null" @click="$verPaseMedico(item.Id)"><v-icon dense
                    color="grey darken-2">
                    mdi-eye-outline
                  </v-icon> Ver pase médico
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import EndPointNet from "@/functions/axios/axios-bearer";
import EspecialidadesComponent from "@/components/EspecialidadesComponent.vue";
import MedicosComponent from "@/components/MedicosComponent.vue";
import BenediciadosComponent from "@/components/user/BeneficiadosComponent.vue";


export default {
  name: "CitasCliente",
  created() {
    this.getData()
    this.getNumCitas()
  },
  components: {
    EspecialidadesComponent, MedicosComponent, BenediciadosComponent
  },
  data: () => ({
    numerocitas: 0,
    estado: 0,
    nuevacita: null,
    especialidad: '',
    medico: '',
    beneficiado: '',
    valid: false,
    isModal: false,
    list: [],
    search: "",
    headers: [
      //{ text: "Id", value: "Id", sortable: false },
      { text: "Folio", value: "Folio", sortable: false },
      { text: "Parentesco", value: "Parentesco", sortable: false },
      { text: "Beneficiado", value: "Beneficiado", sortable: false },
      { text: "Especialidad", value: "Especialidad", sortable: true },
      { text: "Medico", value: "Medico", sortable: true },
      { text: "Estatus", value: "FecCierre", sortable: true },
      { text: "Creada", value: "created_at", sortable: true },
      { text: "Opciones", value: "ops", sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(["getIdTarjetaTxt", "getIdTarjeta"]),
  },
  methods: {
    updateEsp(esp) {
      this.especialidad = esp
    },
    updateMed(med) {
      this.medico = med.IdMedico
    },
    updateBen(ben) {
      this.beneficiado = ben.Id
    },
    GenerarPase() {
      this.isModal = true
    },
    crearCita() {

      if (this.getIdTarjeta !== '' && this.especialidad !== '' && this.medico !== '' && this.beneficiado !== '') {
        this.$loading(true);
        EndPointNet(`membresia/citamedica`, "POST",
          {
            idMem: this.getIdTarjeta,
            idEsp: this.especialidad,
            idMed: this.medico,
            idBen: this.beneficiado
          }
        )
          .then((response) => {
            this.especialidad = ''
            this.medico = ''
            this.beneficiado = ''
            this.isModal = false
            if (response.status === 200) {
              this.nuevacita = response.data;
              this.getData()
              this.getNumCitas()
              // this.search = response.data

              this.$loading(false);
              this.$swal({
                position: 'top-end',
                title: 'Correcto',
                text: 'Su pase médico se genero correctamente',
                icon: 'success',
                confirmButtonText: 'Cerrar',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
              })
            } else {
              this.isModal = false
              this.$loading(false);
              this.$swal({
                title: 'Infomación',
                text: 'No tienes pases sificientes puedes hacer una recarga de 5 a un costo especial contacta a tu agente.',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
              });
            }
          })
          .catch(() => {
            this.$loading(false);
            this.especialidad = ''
            this.medico = ''
            this.beneficiado = ''
            this.isModal = false
            this.$swal({
              // position: 'top-end',
              title: 'Error',
              text: `Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: false
              // timer: 1500,
            })
          });
      } else {
        this.especialidad = ''
        this.medico = ''
        this.beneficiado = ''
        this.$swal({
          // position: 'top-end',
          title: 'Infomación',
          text: `Selecciona una especialidad, un médico y para quien será el pase.`,
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          showCloseButton: false
          // timer: 1500,
        })
      }
    },
    cerrar() {
      this.especialidad = ''
      this.medico = ''
      this.beneficiado = ''
      this.isModal = false
    },
    getData() {
      this.$loading(true);
      EndPointNet(`membresia/citas${this.getIdTarjeta}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.list = response.data;
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
    getNumCitas() {
      this.$loading(true);
      EndPointNet(`Membresia/Numerocitas${this.getIdTarjeta}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.estado = response.data[0].Estatus
            this.numerocitas = response.data[0].NumCitas
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
  },
};
</script>
