<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn text v-bind="attrs" v-on="on">Enviar por correo</v-btn> -->
      <v-list-item-title v-bind="attrs" v-on="on"> <v-icon dense color="grey darken-2">
          mdi-email-fast
        </v-icon> Enviar pase médico</v-list-item-title>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Enviar pase médico</span>
      </v-card-title>
      <v-card-text class="bg-surface-light pt-4">
        Se enviará el pase médico mediante el <strong>correo ingresado</strong>
        anexando el .pdf 
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="sendEmail">
          <v-text-field v-model="email" label="Correo electrónico" :rules="[rules.required, rules.email]"
            required></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="sendEmail">Enviar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '../plugins/event-bus'; // Importa el bus de eventos

export default {
  computed: {
    ...mapGetters(['getEmail'])
  },
  mounted() {
    this.email = this.propEmail ? this.propEmail: this.getEmail
  },
  props: {
    Id: {
      type: String,
      required: true,
      default: ''
    },
    propEmail: {
      type: String,
      required: false,
      default:''
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      email: '',
      subject: '',
      message: '',
      rules: {
        required: value => !!value || 'Requerido.',
        email: value => {
          const pattern = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/
          return pattern.test(value) || 'Correo electrónico inválido.'
        }
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      
    },
    sendEmail() {
      this.$loading(true)

      if (this.$refs.form.validate()) {
        fetch(`${process.env.VUE_APP_BUCKETFORMATOS}cita/SendMail/${this.Id}/${this.email}`, {
          method: 'GET'
        })
          .then(response => {
            this.$loading(false)
            if (response.status === 200) {
              this.$loading(false)
              EventBus.$emit('show-snackbar', {
                text: `El correo fue enviado correctamente.`,
                color: 'success',  // Puedes cambiar el color
                timeout: 5000   // Puedes ajustar el tiempo
              });
            }else{
              this.$swal({
                position: '',
                html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${response.statusText}`,
                icon: 'error',
                confirmButtonText: 'Cerrar',
                showCloseButton: true,
                showConfirmButton: true,
                //timer: 1500,
              })
            }
            return response.status 
          })
          .catch(error => {
            this.$loading(false)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado, <b>intenta mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
              //timer: 1500,
            })
          });
      }
      
      // Reiniciar el formulario y cerrar el modal
      // this.email = '';
      this.dialog = false;
    }
  }
}
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>