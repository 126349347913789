<template>
    <v-container>
      <v-combobox  clearable dense hide-selected outlined v-model="selectedValue" :items="items" item-text="Nombre"
        item-value="Id" label="¿Para quién es el pase?" persistent-hint return-object single-line width="140"
        @change="emitValue"></v-combobox>
    </v-container>
  </template>
  
  <script>
  import EndPointNet from "@/functions/axios/axios-bearer";
  import { mapGetters } from 'vuex'

  export default {
    name: "BeneficiadosComponent",
    computed: {
      ...mapGetters(['getIdTarjeta'])
    },
    data() {
      return {
        selectedValue: '', // Valor seleccionado
        items: [], // Opciones disponibles
      };
    },
    created(){
     this.getEspecialidades()   
    },
    methods: {
      emitValue() {
        // Emitir el valor seleccionado al componente padre
        this.$emit("value-changed", this.selectedValue);
      },
     getEspecialidades(){
        this.$loading(true);
      EndPointNet(`/Membresia/beneficiados${this.getIdTarjeta}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data;
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos opcionales */
  </style>
  