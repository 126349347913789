<template>
    <v-container>
      <v-combobox  clearable dense hide-selected outlined v-model="selectedValue" :items="items" item-text="Nombre"
        item-value="IdMedico" label="¿Cuál médico prefieres?" persistent-hint return-object single-line width="140"
        @change="emitValue"></v-combobox>
    </v-container>
  </template>
  
  <script>
  import EndPointNet from "@/functions/axios/axios-bearer";

  export default {
    name: "MedicosComponent",
    props: {
      // Prop para inicializar el valor si es necesario
      initialValue: {
        type: String,
        default: "",
        requerid: false
      },
      especialidadValue:{
        type: String,
        default: "",
        requerid: false
      }
    },
    watch: {
      especialidadValue(newVal, oldValue) {
        if(newVal !== oldValue){
          this.selectedValue = ''
          this.especialidadSelect = newVal
          this.getEspecialidades()  
        }
      }
    },
    data() {
      return {
        selectedValue: '', // this.initialValue, // Valor seleccionado
        items: [], // Opciones disponibles
        especialidadSelect : ''
      };
    },
    created(){
     // this.getEspecialidades()   
    },
    methods: {
      emitValue() {
        // Emitir el valor medico al componente padre
        this.$emit("value-changed", this.selectedValue);
      },
     getEspecialidades(){
        this.$loading(true);
      EndPointNet(`medico/${this.especialidadSelect}`, "GET")
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data;
            this.$loading(false);
          }
        })
        .catch(() => {
          this.$loading(false);
        });
    },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos opcionales */
  </style>
  