<template>
  <v-container>
    <!-- Diálogo citas médicas-->
    <v-dialog v-model="isModalCita" max-width="90%" persistent >
      <v-card>
        <v-card-title>
          <span class="text-h5">Pases médicos</span>
        </v-card-title>

        <v-card-text>
          Elige la <strong>especialidad médica,</strong> <strong>médico</strong>  y <strong>beneficiario</strong> que 
          acudirá a la cita. Número de citas disponibles: <strong>{{ this.MembresiaSelecNumCitas }}</strong> 
          Estatus: <strong>{{ this.MembresiaSelecEstatus }}</strong> 
          <v-row v-show="this.MembresiaSelecNumCitas > 0 && this.MembresiaSelecEstatus === 'ACTIVO'">
          <v-col cols="6" xs="6" sm="3" md="6" lg="3" xl="3">
            <especialidades-component @value-changed="updateEsp"></especialidades-component>
                </v-col>
                <v-col cols="6" xs="6" sm="3" md="4" lg="3" xl="3">
                  <medicos-component v-show="this.especialidad" @value-changed="updateMed"
                  :especialidadValue="this.especialidad"></medicos-component>
                </v-col>
                <v-col cols="6" xs="6" sm="3" md="4" lg="3" xl="3">
                  <benediciados-component v-show="this.medico" @value-changed="updateBen"
            :initialmembresiaValue="this.MembresiaSelec"></benediciados-component>
                </v-col>
                <v-col cols="6" xs="6" sm="3" md="4" lg="3" xl="3">
                  <v-btn  color="azul_med" @click.native="crearCita" dark>+
                    GENERAR PASE </v-btn>
                </v-col>
              </v-row>
          <citas-medicas :Membresia="this.MembresiaSelec"></citas-medicas>
        </v-card-text>
        <v-card-actions >
          <v-spacer></v-spacer>
          <!-- Botón para cerrar el diálogo -->
          <v-btn text @click="cerrar">
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-flex flex-wrap align-center justify-end">
      <v-chip class="ma-2" color="azul_med" label dark>
        <v-icon start>mdi-chevron-right-circle-outline</v-icon>
        <v-list-item-subtitle>Activas: {{ this.activas }}</v-list-item-subtitle>
      </v-chip>
      <v-chip class="ma-2" color="rojoalert" label dark>
        <v-icon start>mdi-alert-circle-outline</v-icon>
        <v-list-item-subtitle>Impago: {{ this.suspendidas }}</v-list-item-subtitle>
      </v-chip>
      <v-chip class="ma-2" color="grey" label dark>
        <v-icon start>mdi-alert-circle-outline</v-icon>
        <v-list-item-subtitle>Bajas: {{ this.totalbajas }}</v-list-item-subtitle>
      </v-chip>
    </div>
    <p class="font-weight-regular">
      Listado de <strong>membresías</strong>, puedes agrupar por colectividad.
    </p>
    <v-row class="bordered-row">
      <!-- <v-col class="mr-1">
        <list-agentes @agent-change="changeAgent"></list-agentes>
      </v-col> -->
      <v-col xs="12" sm="12" md="6" lg="4" xl="4">
        <list-colectivos :propAdmin="false" @colectivo-change="changeColectividad"></list-colectivos>
      </v-col>
      <!-- <v-col xs="12" sm="12" md="6" lg="4" xl="4">
        <v-text-field class="col" v-model="searchBeneficiado"  label="Buscar beneficiado" single-line hide-details
          outlined dense></v-text-field>
      </v-col> -->
      <v-col xs="12" sm="12" md="2" lg="2" xl="2">
        <v-btn color="azul_med" @click.native="buscarColectivo" dark>Buscar</v-btn>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col sm="12" md="4" lg="4" xl="4">
        <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar en listado" single-line hide-details
          outlined dense></v-text-field>
      </v-col>
    </v-row>
    <!--group-by="AgeNom" show-select -1       fixed-header
      height="400px"-->
    <v-data-table :items-per-page="5" calculate-widths :headers="headers" :items="contratos" item-key="GUID"
      :search="search" :group-by="['Agente']" no-data-text="No hay datos disponibles" :footer-props="customFooterProps">
      <template v-slot:[`item.index`]="{ index }">
        <td>{{ index + 1 }}</td>
      </template>
      <template v-slot:[`item.Plan`]="{ item }">
        <div class="card">
          <div class="card-header">{{ item.Plan }} </div>
          <div class="card-body">
            Citas restantes: {{ item.NumCitas }} <br />
            Urgencias: {{ $formatCurrency(item.MontoUrgencias) }} <br />
            Coberturas: {{ item.Coberturas }} <br />
            Tipo pago: {{ item.TipoPago }} <br />
          </div>
        </div>
      </template>
      <template v-slot:[`item.ContNumTel`]="{ item }">
        <td><small><a v-if="item.ContNumTel" @click="$copyText(item.ContNumTel)">{{ item.ContNumTel }} </a><br />
            <a @click="$copyText(item.ContEmail)" v-if="item.ContEmail">{{ item.ContEmail
              }}</a></small></td>
      </template>
      <template v-slot:[`item.GUID`]="{ item }">
        <td>{{ item.GUID }}</td>
      </template>
      <!-- @click:row="handleRowClick" -->
      <template v-slot:[`item.Estatus`]="{ item }">
        <td><span v-if="item.Estatus === 'ACTIVO'" class="estatus-activo">{{ item.Estatus }}</span>
          <span v-if="item.Estatus === 'SUSPENDIDO'" class="estatus-impago">{{ item.Estatus }}</span>
          <span v-if="item.Estatus === 'BAJA'" class="estatus-expirado">{{ item.Estatus }}</span>
        </td>
      </template>
      <template v-slot:[`item.MontoUrgencias`]="{ item }">
        <td>
          <span v-if="item.MontoUrgencias === 0" class="urgencias0">
            {{ $formatCurrency(item.MontoUrgencias) }}
          </span>
          <span v-else>
            {{ $formatCurrency(item.MontoUrgencias) }}
          </span>
        </td>
      </template>
      <template v-slot:[`item.FecIniVig`]="{ item }">
        <td>{{ $formatDate(item.FecIniVig) }}</td>
      </template>
      <template v-slot:[`item.FecAlta`]="{ item }">
        <td>{{ $formatDate(item.FecAlta) }}</td>
      </template>
      <template v-slot:[`item.DatosActualizadosPortal`]="{ item }">
        <td> <span v-if="item.DatosActualizadosPortal"> {{ $formatDate(item.DatosActualizadosPortal) }}</span>
          <span v-else><v-icon dense color="red darken-2">
              mdi-close
            </v-icon></span>
        </td>
      </template>
      <template v-slot:[`item.Titular`]="{ item }">
        <td><small> {{ item.Titular }}</small></td>
      </template>
      <template v-slot:[`item.Contratante`]="{ item }">
        <td>
          <v-chip v-if="item.Colectividad"
            style="max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="ma-1" label
            text-color="greey" x-small>
            <v-icon left>
              mdi-label
            </v-icon>
            {{ item.Colectividad }}
          </v-chip><br />
          <small
            v-if="item.Contratante.replace(/\s+/g, '').toUpperCase() != item.Titular.replace(/\s+/g, '').toUpperCase()">
            {{ item.Contratante }}
          </small>
        </td>
      </template>
      <template v-slot:[`item.Colectividad`]="{ item }">
        <td>
          <small>{{ item.Colectividad }}</small>
        </td>
      </template>
      <template v-slot:[`item.Membresia`]="{ item }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn red icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item  @click="modalCita(item)">
                <v-list-item-title> Pases médicos</v-list-item-title>
              </v-list-item>
            <v-list-item :disabled="(item.Estatus === 'BAJA')" @click="editItem(item)">
              <v-list-item-title>
                <ButtonMembresia :propObjTarjet="item"></ButtonMembresia>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="verPagos(item.GUID, item.TotRecibos)">
              <v-list-item-title> Ver recibos</v-list-item-title>
            </v-list-item>
            <v-list-item @click="verBeneficiarios(item.GUID)">
              <v-list-item-title> Ver Beneficiarios</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <list-pagos :isOpen.sync="isModLisPagos" :Membresia="this.MembresiaSelec"
      :NumRecibos="this.NumRecibosSelec"></list-pagos>
    <list-beneficiarios :isOpen.sync="isModLisBen" :Membresia="this.MembresiaSelec"></list-beneficiarios>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'
import EndPointNet from "@/functions/axios/axios-bearer";

import EspecialidadesComponent from "@/components/EspecialidadesComponent.vue";
import MedicosComponent from "@/components/MedicosComponent.vue";
import BenediciadosComponent from "@/components/Agente/BeneficiadosComponent.vue";
import CitasMedicas from "@/components/CitasMedicas.vue";


export default {
  name: 'Membresias',
  components: {
    ButtonMembresia: () => import('@/components/ButtonMembresia.vue'),
    ListColectivos: () => import('@/components/Forms/ColectivosListComponent.vue'),
    ListPagos: () => import('@/components/ListPagoComponent.vue'),
    ListBeneficiarios: () => import('@/components/ListBeneficiariosComponent'),
    EspecialidadesComponent,
    MedicosComponent,
    BenediciadosComponent,
    CitasMedicas
  },
  computed: {
    ...mapGetters(['getCODAGE'])
  },
  mounted() {
    this.getMembresiasAgente()
  },
  data() {
    return {

      isModalCita: false,
      nuevacita: null,
      especialidad: '',
      medico: '',
      beneficiado: '',

      MembresiaSelecNumCitas: '',
      MembresiaSelecEstatus: 0,
      searchBeneficiado:'',
      grouped: {},
      totalbajas: 0,
      MembresiaSelec: null,
      NumRecibosSelec: 0,
      isModLisBen: false,
      isModLisPagos: false,
      idcolectividad: null,
      idagente: null,
      activas: 0,
      suspendidas: 0,
      search: '',
      contratos: [],
      headers: [
        { text: "#", value: "index", align: "start", width: 10  },
        { text: 'Agente', value: 'Agente', sortable: false, width: 100 },
        { text: 'Membresia', value: 'GUID', sortable: true, width: 80 },
        { text: 'Plan', value: 'Plan', sortable: false, width: 150},
        { text: 'Vigencia Inicio', value: 'FecIniVig', sortable: true, width:50 },
        { text: 'Alta', value: 'FecAlta', sortable: true, width:50  },
        { text: 'Contratante', value: 'Contratante', sortable: false },
        { text: 'Titular', value: 'Titular', sortable: false },
        { text: 'Estatus', value: 'Estatus', sortable: true },
        { text: 'Opciones', value: 'Membresia', sortable: false },
      ],
      customFooterProps: {
        itemsPerPageText: "Elementos por página:", 
        itemsPerPageAllText: "Todos", 
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-text': 'Filas por página'
      }
    }
  },
  methods: {
    modalCita(item) {
      console.log(item)
      this.MembresiaSelec = item.GUID
      this.MembresiaSelecNumCitas = item.NumCitas
      this.MembresiaSelecEstatus = item.Estatus
      this.isModalCita = true
    },
    updateEsp(esp) {
      this.medico = ''
      this.especialidad = esp
      
    },
    updateMed(med) {
      this.medico = med?.IdMedico
    },
    updateBen(ben) {
      this.beneficiado = ben?.Id
    },
    GenerarPase() {
      this.isModal = true
    },
    crearCita() {

      if (this.MembresiaSelec !== '' && this.especialidad !== '' && this.medico !== '' && this.beneficiado !== ''
        && this.MembresiaSelec !== undefined && this.especialidad !== undefined && this.medico !== undefined && this.beneficiado !== undefined) {
        this.$loading(true);
        EndPointNet(`agente/citamedica`, "POST",
          {
            idMem: this.MembresiaSelec,
            idEsp: this.especialidad,
            idMed: this.medico,
            idBen: this.beneficiado
          }
        )
          .then((response) => {
            this.especialidad = ''
            this.medico = ''
            this.beneficiado = ''
            this.isModalCita = false
            if (response.status === 200) {
              this.nuevacita = response.data;
              this.$loading(false);

              this.$swal({
                position: 'top-end',
                title: 'Correcto',
                text: 'Su pase médico se genero correctamente, en un momento visualizaras el pase médico',
                icon: 'success',
                confirmButtonText: 'Cerrar',
                showCloseButton: false,
                showConfirmButton: false,
                timer: 1500
              })

              this.$verPaseMedico(this.nuevacita)
            } else {
              this.$loading(false);
              this.$swal({
                title: 'Infomación',
                text: 'No tienes pases sificientes puedes hacer una recarga de 5 a un costo especial contacta a tu agente.',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
              });
            }
          })
          .catch(() => {
            this.$loading(false);
            this.especialidad = ''
            this.medico = ''
            this.beneficiado = ''
            this.isModalCita = false
            this.$swal({
              // position: 'top-end',
              title: 'Error',
              text: `Hemos detectado un error, el cual ya ha sido reportado. Por favor, intenta nuevamente más tarde.`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: false
              // timer: 1500,
            })
          });
      } else {

        this.$swal({
          // position: 'top-end',
          title: 'Infomación',
          text: `Selecciona una especialidad, un médico y para quien será el pase.`,
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          showCloseButton: false
          // timer: 1500,
        })
      }
    },
    cerrar() {
      this.especialidad = ''
      this.medico = ''
      this.beneficiado = ''
      this.isModalCita = false
    },


    verPagos(mem, numrecibos) {
      this.MembresiaSelec = mem
      this.NumRecibosSelec = numrecibos
      this.isModLisPagos = true
    },
    verBeneficiarios(mem) {
      this.MembresiaSelec = mem
      this.isModLisBen = true
    },
    buscarColectivo() {
      this.getMembresiasAgente()
    },
    changeColectividad(value) {
      this.idcolectividad = value
    },
    changeAgent(value) {
      this.idagente = value
    },
    getMembresiasAgente() {
      if (this.getCODAGE && this.getCODAGE !== 'null') {
        this.$loading(true)
        var url = `getMembresiasAgente?Agente=${this.getCODAGE}&Colectivo=${this.idcolectividad}`
        ApiBase(
          url,
          'GET'
        ).then((response) => {
          if (response.status === 200) {
            if (response.data) {
              this.contratos = response.data
              this.activas = this.contratos.filter(item => item.Estatus === 'ACTIVO').length;
              this.suspendidas = this.contratos.filter(item => item.Estatus === 'SUSPENDIDO').length;
              this.totalbajas = this.contratos.filter(item => item.Estatus === 'BAJA').length;
              this.$loading(false)
            }
          }
        })
          .catch((error) => {
            this.$loading(false)
            this.$swal({
              position: '',
              html: `Ha ocurrido un error inesperado membresías agente, <b>intenta mas tarde</b> . ${error}`,
              icon: 'error',
              confirmButtonText: 'Cerrar',
              showCloseButton: true,
              showConfirmButton: true,
            })
          });
      }

    }
  }
}

</script>
<style lang="scss">

</style>
